import moment from "moment";
import { DownloadFileModel } from "../models/Interfaces";
import { api } from "@enerbit/base";
import Papa from "papaparse";
import {
  Inconsistencies,
  InconsistencyTypes,
  ScheduledVsExecutedItem,
  Status,
} from "../models/scheduledVsExecuted";

export const createDownloadLink = ({ data, fileName, fileType }: DownloadFileModel) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const downloadFile = (data: any[], fileName: string, headers: string[]) => {
  const sanitizedData = data.map(sanitizeObject);

  console.log(sanitizedData);

  const csv = Papa.unparse({
    fields: headers,
    data: sanitizedData,
  });

  createDownloadLink({
    data: "\uFEFF" + csv,
    fileName,
    fileType: "text/csv;charset=utf-8;",
  });
};

export const formatDate = (date: string | Date) => {
  let fechaLocal = moment.utc(date).local();
  return fechaLocal.format("YYYY-MM-DD hh:mm:ss A");
};

export const validateDateRange = (begin: string | Date, end: string | Date) => {
  const tempBegin = new Date(begin);
  const tempEnd = new Date(end);

  const diference = tempEnd.getTime() - tempBegin.getTime();

  const days = diference / 1000 / 60 / 60 / 24;

  return days;
};

export const formatMoney = (value: number) => {
  if (isNaN(value)) {
    return "Sin registro";
  }

  const formatedValue = value.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatedValue;
};

export const getHour = (date: string) => {
  const newDate = moment(date);
  return newDate.format("hh:mm");
};

export const authFile = async (url: string) => {
  const { data } = await api.get("/service-orders-certificates/authenticate-file", {
    params: { url_path: url },
  });

  return data.url;
};

const sanitizeObject = (obj: any) => {
  return Object.keys(obj).reduce((sanitizedObj, key) => {
    sanitizedObj[key] = sanitizeValue(obj[key]);
    return sanitizedObj;
  }, {});
};

const sanitizeValue = (value: any) => {
  if (typeof value === "string") {
    return value.replace(/(\r\n|\n|\r|\t)/gm, " ");
  }
  return value;
};

export const formatOrderInconsistencies = (order: ScheduledVsExecutedItem) => {
  const inconsistencies: Record<string, string> = {};
  const inconsistencyNames: Record<InconsistencyTypes, string> = {
    different_location: "Orden cerrada en una locación diferente",
    exceeded_time: "Tiempo de ejecución de la orden excedido",
    late_start: "Orden iniciada después de la hora programada",
  };

  if (order.order_done) {
    if (order.order_done.form_data.inconsistencies) {
      let text = "";
      order.order_done.form_data.inconsistencies.forEach((item) => {
        const formated = `Tipo: ${inconsistencyNames[item.inconsistency_type]}. Razón: ${
          item.reason
        }\n\n`;

        text += formated;
      });
      inconsistencies["IRREGULARIDADES"] = text;
    } else {
      inconsistencies["IRREGULARIDADES"] =
        "No se presentaron inconsistencias al completar la orden";
    }
  } else {
    console.log("no aplica");
    inconsistencies["IRREGULARIDADES"] = "No aplica";
  }

  return inconsistencies;
};

export const getOrderStatus = (status: Status) => {
  const statusToNames: Record<Status, string> = {
    [Status.Done]: "Ejecutada",
    [Status.Cancel]: "Cancelada",
    [Status.Pending]: "Pendiente",
    [Status.Partial_Done]: "Completada parcialmente",
    [Status.Pending_Assigment]: "Pendiente por asignar",
  };

  return statusToNames[status];
};
