import { createAsyncThunk } from "@reduxjs/toolkit";
import type { FormLabelI } from "../../models/Interfaces";

import moment from "moment";

import { rowJson } from "../../helpers/scheduledVsExecuteJson";

import { initializeEventSource } from "../../helpers/eventSource";
import { getAcessToken } from "../../helpers/token";
import type { ScheduledVsExecutedItem } from "../../models/scheduledVsExecuted";

export const getScheduledVsExecuted = createAsyncThunk(
  "reports/getScheduledVsExecuted",
  async (
    { data, isClose = false }: { data: FormLabelI; isClose?: boolean },
    { dispatch }
  ) => {
    try {
      if (isClose) {
      }
      if (!data.since || !data.until || !data.status || !data.state) return;

      const status = data.status;
      let since = moment
        .utc(data.since)
        .startOf("day")
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss");
      let until = moment
        .utc(data.until)
        .endOf("day")
        .add(5, "hours")
        .format("YYYY-MM-DDTHH:mm:ss");
      const state = data.state;

      const params = {
        status,
        since,
        until,
        state,
      };

      const token = getAcessToken();
      const itemsArray = (await initializeEventSource(
        "/reports/sse/scheduled-vs-executed",
        "scheduled-vs-executed",
        token,
        dispatch,
        params,
        isClose
      )) as ScheduledVsExecutedItem[];

      const mappedItems = itemsArray.map((item) => {
        let location =
          item.order_done?.form_data.geolocalization ||
          item.order_cancel?.form_data.geolocalization;

        let locationOrder = location
          ? `Altitud: ${location.altitude}, longitud: ${location.longitude} y latitud: ${location.latitude}`
          : "No aplica";

        const row = rowJson(item, locationOrder);

        return row;
      });

      return mappedItems;
    } catch (error) {
      console.log(error);
    }
  }
);
