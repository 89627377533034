import { createSlice } from "@reduxjs/toolkit";
import { downloadFile } from "../../../helpers/reports";
import { ReportsState } from "../../../models/Interfaces";
import { getFrontierReports, getMaterialReports, getScheduledVsExecuted } from "../../thunks";
import { getDiagnosticMaterialReport } from "../../thunks/materialDiagnosticsReport";
import { getDiagnosticMaterialSolarReport } from "../../thunks/materialDiagnosticsSolarReport";

const initialState: ReportsState = {
  data: [],
  isLoadingReports: false,
  hasError: false,
  success: false,
  downloadProgress: 0,
  empty: false,
  invalidRangeDate: false,
  currentId: "",
};

export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState,
  reducers: {
    updateHasError(state) {
      state.hasError = !state.hasError;
    },
    updateDownloadProgress(state, { payload }) {
      state.downloadProgress = payload.downloadProgress;
      if (payload?.currentId) {
        state.currentId = payload?.currentId;
      }
    },
    updateEmpty(state, { payload }) {
      state.empty = payload;
    },
    updateIsLoading(state, { payload }) {
      state.isLoadingReports = payload;
    },
    updateInvalidRangeDate(state, { payload }) {
      state.invalidRangeDate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFrontierReports.pending, (state) => {
      state.isLoadingReports = true;
      state.hasError = false;
      state.empty = false;
    });
    builder.addCase(getFrontierReports.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.isLoadingReports = false;
      state.empty = false;
      state.downloadProgress = 0;
      if (payload.length > 0) {
        const headers = Object.keys(payload[0]);
        downloadFile(payload, "Reporte de control de fronteras.csv", headers);
      }
    });
    builder.addCase(getFrontierReports.rejected, (state) => {
      state.isLoadingReports = false;
      state.hasError = true;
      state.empty = false;
      state.downloadProgress = 0;
    });
    builder.addCase(getScheduledVsExecuted.pending, (state) => {
      console.log("pending");
      state.isLoadingReports = true;
      state.hasError = false;
      state.empty = false;
      state.invalidRangeDate = false;
    });
    builder.addCase(getScheduledVsExecuted.fulfilled, (state, { payload }) => {
      console.log("fulfilled");
      if (!payload) return;
      state.empty = payload.length === 0;
      state.downloadProgress = 0;
      state.isLoadingReports = false;
      state.invalidRangeDate = false;
      if (payload.length > 0) {
        const headers = Object.keys(payload[0]);
        downloadFile(payload, "Reporte de ordenes ejecutadas vs programadas.csv", headers);
      }
    });
    builder.addCase(getScheduledVsExecuted.rejected, (state) => {
      console.log("rejected");
      state.isLoadingReports = false;
      state.hasError = true;
      state.empty = false;
      state.downloadProgress = 0;
      state.invalidRangeDate = false;
    });
    builder.addCase(getMaterialReports.pending, (state) => {
      state.isLoadingReports = true;
      state.hasError = false;
      state.empty = false;
      state.invalidRangeDate = false;
    });
    builder.addCase(getMaterialReports.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.empty = payload.length === 0;
      state.downloadProgress = 0;
      state.isLoadingReports = false;
      state.invalidRangeDate = false;
      if (payload.length > 0) {
        const headers = Object.keys(payload[0]);
        downloadFile(payload, "Reporte de materiales instalados.csv", headers);
      }
    });
    builder.addCase(getMaterialReports.rejected, (state) => {
      state.isLoadingReports = false;
      state.hasError = true;
      state.empty = false;
      state.downloadProgress = 0;
      state.invalidRangeDate = false;
    });
    builder.addCase(getDiagnosticMaterialReport.pending, (state) => {
      state.isLoadingReports = true;
      state.hasError = false;
      state.empty = false;
      state.invalidRangeDate = false;
    });
    builder.addCase(getDiagnosticMaterialReport.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.empty = payload.length === 0;
      state.downloadProgress = 0;
      state.isLoadingReports = false;
      state.invalidRangeDate = false;
      if (payload.length > 0) {
        const headers = Object.keys(payload[0]);
        downloadFile(
          payload,
          "Reporte de materiales proyectados en el diagnostico de viabilidad y cumplimiento.csv",
          headers
        );
      }
    });
    builder.addCase(getDiagnosticMaterialReport.rejected, (state, action) => {
      state.isLoadingReports = false;
      state.hasError = true;
      state.empty = false;
      state.downloadProgress = 0;
      state.invalidRangeDate = false;
    });
    builder.addCase(getDiagnosticMaterialSolarReport.pending, (state) => {
      state.isLoadingReports = true;
      state.hasError = false;
      state.empty = false;
      state.invalidRangeDate = false;
    });
    builder.addCase(getDiagnosticMaterialSolarReport.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.empty = payload.length === 0;
      state.downloadProgress = 0;
      state.isLoadingReports = false;
      state.invalidRangeDate = false;

      if (payload.length > 0) {
        const headers = Object.keys(payload[0]);
        downloadFile(
          payload,
          "Reporte de materiales proyectados en el diagnostico de viabilidad y cumplimiento.csv",
          headers
        );
      }
    });
    builder.addCase(getDiagnosticMaterialSolarReport.rejected, (state, action) => {
      state.isLoadingReports = false;
      state.hasError = true;
      state.empty = false;
      state.downloadProgress = 0;
      state.invalidRangeDate = false;
    });
  },
});

export const { updateHasError, updateDownloadProgress, updateEmpty, updateIsLoading, updateInvalidRangeDate } =
  reportsSlice.actions;

export default reportsSlice.reducer;
